@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

body {
  font-family: "Inter";
}

h3 {
  font-size: 24px;
  font-weight: 600;
  font-family: "Inter";
  line-height: 29px;
}
.bg {
  background-image: url("./assets/BannerTab.svg");
}

.logoMobile {
  display: none;
}

.search-container {
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 50px;
  padding: 5px 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  margin: 0 auto;
  position: relative;
  bottom: 22px;
}
.search-container-product {
  display: flex;
  align-items: center;
  border-radius: 500px;
  padding: 4px;
  width: 463px;
  height: 48px;
  border: 1px solid #ffffffb2;
  color: #ffffff;
}

.search-input {
  border: none;
  outline: none;
  background: none;
  flex: 1;
  padding: 8px;
  border-radius: 20px;
  font-size: 16px;
}

.search-icon {
  color: #888;
  cursor: pointer;
}

.search-icon:hover {
  color: #555;
}

.product-list {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  padding: 0;
  list-style-type: none;
}

.custom-gallery .image-gallery-slide img {
  width: 100%;
  height: auto;
  max-height: 100px;
  background: #f8f8f8;
  border-radius: 10px;
}

.custom-gallery .image-gallery-thumbnail img {
  width: 100px;
  height: auto;
  max-height: 100px;
}

.image-gallery-left-nav,
.image-gallery-right-nav {
  padding: 50px 10px;
  top: 40% !important;
  transform: translateY(-25%) !important;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 35px !important;
  width: 25px !important;
}

.image-gallery-fullscreen-button,
.image-gallery-play-button {
  bottom: 0;
  padding: 0px !important;
  margin: 15px !important;
}

.product-thumbnail {
  margin: auto;
  width: 100%;
  max-width: 140px;
  height: 150px;
  padding: 20px;
}

.shop-list {
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */
}

.shop-list::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.shop-item {
  flex: 0 0 auto; /* Ensure items don't shrink */
  margin-right: 1rem; /* Add some spacing between items */
  display: flex;
  gap: 10px;
  width: 268px;
  word-break: break-word;
}

.shop-image {
  width: 66px;
  height: 66px;
  object-fit: cover;
  border-radius: 50%;
}

/* Trending Product */
.product-container {
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  -webkit-overflow-scrolling: touch !important;
  scrollbar-width: none !important;
}

.product-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.product-list-shops::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}
.product-list-container::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.product-list {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.product-images {
  overflow-x: auto;
  overflow-y: hidden;
  flex-direction: row;
}

.product-image {
  width: 66px;
  height: 66px;
  object-fit: cover;
  margin-right: 10px;
}

/* Hide scrollbar for Webkit browsers (Chrome, Safari) */
.horizontal-scroll::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.horizontal-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Enable horizontal scrolling */
.horizontal-scroll {
  display: flex;
  overflow-x: auto;
  flex-wrap: nowrap;
}

.Trending-product-list {
  display: flex;
  flex-wrap: nowrap;
}

.product-item {
  flex: 0 0 auto;
  margin-right: 0px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  height: auto;
  /* max-height: 45vh; */
}
.home-product-item {
  flex: 0 0 auto;
  margin-right: 0px;
  margin-bottom: 30px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
  height: auto;
  /* max-height: 50vh; */
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
  margin: auto;
  padding: 50px;
  height: 60vh;
}

.product-list-shops {
  display: flex;
  list-style-type: none;
  padding: 0;
  overflow-x: auto; /* Enable horizontal scroll if needed */
  -webkit-overflow-scrolling: touch; /* Enables smooth scrolling on iOS */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none;
}

.product-item-shops {
  width: 100%;
  max-width: auto;
  margin-right: 2rem;
  margin-bottom: 40px;
  border: 1px solid #0000001a;
  border-radius: 5px;
  padding: 10px;
}

.product-image-shops {
  width: 80px;
  height: 80px;
}

.rounded-image-shops {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.product-details-shops {
  margin-top: 10px; /* Adjust spacing */
}

.boxShadow {
  background-color: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  width: 75%;
}

/* Shop Profile page */
.product-list-container {
  display: flex;
  overflow-x: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.product-list-container::-webkit-scrollbar {
  display: none; /* Chrome, Safari and Opera */
}

.product-list-container li {
  width: 15%;
  flex: 0 0 auto;
  align-items: center;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 10px;
}

.product-list-container img {
  margin: auto;
  width: 100%;
  max-width: 80%;
  height: 130px;
}

.product-list-container h4 {
  margin: 0;
  font-size: 1em;
}

.product-list-container p {
  margin: 5px 0 0;
  font-size: 0.9em;
  color: #555;
}

/* Fixed Header code */
.header-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 84px;
  z-index: 1000;
}

/* Add padding to the main content */
.content {
  padding-top: 94px; /* Same height as your header */
}

.locMobile {
  display: none;
}

/* ToolTip code */
.tooltip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position the tooltip above the text */
  left: 50%;
  margin-left: -60px; /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

/* Top Brands */
.brand-container {
  display: flex;
  flex-wrap: wrap; /* Allows wrapping to the next line if needed */
  gap: 10px; /* Adjust the space between brand cards */
  padding-top: 20px;
}

.brand-card {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  /* background-color: #f9f9f9; */
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  cursor: pointer;
}

.brand-name {
  white-space: nowrap; /* Prevents text from wrapping */
  text-align: center;
  font-size: 16px;
}

@media only screen and (max-width: 768px) {
  /* Home page */
  h3 {
    font-size: 20px;
    font-weight: 600;
    font-family: "Inter";
    line-height: 29px;
  }
  .logoDesktop {
    display: none;
  }

  .logoMobile {
    display: block;
  }

  .homePage p {
    font-size: 14px;
  }

  .search-container {
    width: 90%;
  }

  .loginMobile {
    margin: auto;
    margin-top: 15px;
    width: 100%;
    height: 48px;
  }

  /* Product page */
  .search-container-product {
    margin: auto;
    margin-top: 15px;
    display: flex;
    align-items: center;
    border-radius: 500px;
    padding: 8px;
    width: 100%;
    height: 48px;
    border: 1px solid #ffffffb2;
    color: #ffffff;
  }

  .heightMobile {
    padding-left: 25px;
    padding-right: 25px;
    height: 150px;
    align-content: center;
  }

  .logoLocation {
    justify-content: space-between;
  }
  .bg {
    background-image: url("./assets/BannerMobile.svg");
  }
  .bannerMobile {
    height: 348px;
  }
  .headerMobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 15px;
    padding-right: 15px;
  }
  .locationMobile {
    gap: 30px;
  }
  .homeLogin {
    width: 94px;
    height: 42px;
  }
  .sectionHeight {
    padding-left: 25px;
    padding-right: 25px;
    /* padding-top: 10px; */
    padding-bottom: 10px;
  }

  .boxShadow {
    width: 90%;
  }

  .sectionPadding {
    padding-top: 8rem;
  }

  .product-list-container li {
    width: 35%;
  }
  .loginPadding {
    padding-top: 6px;
  }
  .loginDesktop {
    display: none;
  }

  .mobile-product {
    margin: auto;
    margin-top: 3rem;
    padding-left: 25px;
    padding-right: 25px;
  }
  .product-list {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .product-item {
    width: unset;
    height: auto;
    /* max-height: 35vh; */
  }
  .home-product-item {
    width: unset;
    height: auto;
    /* max-height: 39vh; */
  }

  .image-gallery-thumbnails {
    padding: 20px 0px;
  }
  .image-gallery-content .image-gallery-slide .image-gallery-image {
    margin: auto;
    padding: 50px;
    height: 30vh;
  }
  /* .MobileImage {
    margin-top: 10px;
  } */
  .shopPopup {
    margin-top: 3.5rem;
  }

  .product-list-shops {
    margin-top: 10px;
  }

  .shopImgMobile {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    width: 100%;
  }

  .product-list-container img {
    width: 100%;
    max-width: 100%;
    height: 100px;
  }
  .locMobile {
    display: flex;
  }

  .backBtnMobile {
    padding-left: 30px;
    padding-right: 30px;
  }
  .shop-item {
    width: max-content;
  }
}

@media only screen and (min-width: 769px) and (max-width: 1279px) {
  .bg {
    background-image: url("./assets/BannerTab.svg");
  }
  .product-list-container img {
    width: 100%;
    max-width: 90%;
    height: 120px;
  }
}
@media only screen and (min-width: 1280px) and (max-width: 1440px) {
  .locMobile {
    display: none;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1920px) {
  .bannerMobile {
    height: 348px;
  }
  .locMobile {
    display: none;
  }
}
